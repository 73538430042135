import type { IDynamicForm } from 'shared/config/dynamicForms'

export function useReCaptcha() {
  const { $preferences } = useNuxtApp()
  const config = useRuntimeConfig()

  const isGoogleReCaptchaEnabled = $preferences.isGoogleReCaptchaEnabled
  function initRCScript(innerFields: ComputedRef<IDynamicForm>) {
    if (process.client && isGoogleReCaptchaEnabled && hasReCaptchaForm(innerFields)) {
      addReCaptchaContainer()
      useHead({
        script: [
          {
            src: 'https://www.google.com/recaptcha/api.js?render=' + config.public.googleReCaptchaSiteKey,
            key: 'ReCaptcha'
          }
        ]
      })
    }
  }

  function addReCaptchaContainer() {
    if (!isGoogleReCaptchaEnabled) {
      return
    }
    if (!document.body.querySelector('.g-recaptcha')) {
      const gRecaptchaDiv = document.createElement('div')
      gRecaptchaDiv.classList.add('g-recaptcha')
      gRecaptchaDiv.dataset.size = 'invisible'
      gRecaptchaDiv.dataset.sitekey = config.public.googleReCaptchaSiteKey
      document.body.appendChild(gRecaptchaDiv)
    }
  }

  async function generateClientToken() {
    if (!isGoogleReCaptchaEnabled) {
      return
    }

    return new Promise<string>((resolve) => {
      // @ts-ignore
      grecaptcha.ready(async () => {
        // @ts-ignore
        const token = await grecaptcha.execute()
        resolve(token)
      })
    })
  }

  function hasReCaptchaForm(innerFields: ComputedRef<IDynamicForm>) {
    return innerFields.value?.reCaptcha && innerFields.value?.reCaptcha?.enable
  }

  async function assignRCTokenToForm(
    formModels: { [key: string]: string | boolean },
    innerFields: ComputedRef<IDynamicForm>
  ) {
    if (!isGoogleReCaptchaEnabled && hasReCaptchaForm(innerFields)) {
      return
    }
    const token = await generateClientToken()
    if (token) {
      formModels.reCaptchaToken = token
    }
  }

  return {
    initRCScript,
    generateClientToken,
    assignRCTokenToForm
  }
}
