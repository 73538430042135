<script lang="ts" setup>
import type { AsfFormEmits, AsfFormFieldType, AsfFormProps } from '@ui/types'
import { useForm } from 'vee-validate'
import { componentTypes, getInnerFields, scrollToErrorField } from './Form.utils'
import { AsfAlert, AsfCheckbox, AsfInput, AsfRadio, AsfSelect } from '#components'
import type { IDynamicField } from 'shared/config/dynamicForms'

const { initRCScript, assignRCTokenToForm } = useReCaptcha()

const emit = defineEmits<AsfFormEmits>()
const props = withDefaults(defineProps<AsfFormProps>(), {
  errorAppearance: 'error'
})
const { t } = useI18n()

const formId = computed(() => props.id || props.name)
const formPrefix = computed(() => props.prefix || props.name)
const innerFields = computed(() => getInnerFields(props.fields, t))
const formRef = ref()
const fieldRef = ref([])

type FormFieldComponent = typeof AsfInput | typeof AsfCheckbox | typeof AsfSelect | typeof AsfRadio | string
const getComponent = (field: IDynamicField): FormFieldComponent => {
  const { type: fieldType } = field
  if (!(fieldType in componentTypes)) {
    // eslint-disable-next-line no-console
    console.error(`Unknown field type: ${fieldType}`)
    return ''
  }

  const componentName = componentTypes[fieldType as AsfFormFieldType]
  if (componentName === 'asf-input') {
    return AsfInput
  } else if (componentName === 'asf-checkbox') {
    return AsfCheckbox
  } else if (componentName === 'asf-select') {
    return AsfSelect
  } else if (componentName === 'asf-radio') {
    return AsfRadio
  } else {
    throw new Error(`Unknown field type: ${componentName}`)
  }
}

initRCScript(innerFields)
const { values, resetForm, validate, setFieldValue, setErrors } = useForm({
  initialValues: props.formModel
})

watch(
  () => {
    const keys = Object.keys(innerFields.value)

    return keys.reduce(
      (acc, key) => {
        acc[key] = props.formModel[key]
        return acc
      },
      {} as Record<string, string | boolean | undefined>
    )
  },
  (newValues, oldValues) => {
    const keys = Object.keys(innerFields.value)
    for (const key of keys) {
      const newVal = newValues[key]
      if (newVal !== values[key] && typeof newVal !== 'undefined') {
        setFieldValue(key, newVal, typeof oldValues[key] !== 'undefined')
      }
    }
  }
)

watch(values, (newValues) => {
  Object.keys(newValues).forEach((key) => {
    const val = values[key]

    if (typeof val !== 'undefined') {
      // eslint-disable-next-line vue/no-mutating-props
      props.formModel[key] = val
    }
  })
  emit('update:formModel', values)
})

function applyErrors(error: AsfFormProps['error'] | undefined) {
  if (!error) {
    return
  }

  const validationErrors: Record<string, string> = {}

  Object.entries(error).forEach(([key, value]) => {
    if (!['__general__', '__sidexp__'].includes(key) && typeof value === 'string') {
      validationErrors[key] = t(value)
    }
  })

  setErrors(validationErrors)
}
watch(() => props.error, applyErrors)
applyErrors(props.error)

async function validateForm() {
  await assignRCTokenToForm(props.formModel, innerFields)
  const { valid } = await validate()

  return valid
}

const handleSubmit = () => {
  return validateForm().then((valid) => {
    if (valid) {
      Object.keys(values).forEach((key) => {
        const val = values[key]

        if (typeof val !== 'undefined') {
          // eslint-disable-next-line vue/no-mutating-props
          props.formModel[key] = val
        }
      })
      emit('form:submitted', values)
    } else {
      formRef.value?.querySelector('.is-invalid')?.scrollIntoView(false)
      formRef.value?.querySelector('.is-invalid input')?.focus()
    }
    return valid
  })
}

const resetField = (fieldKey: string) => {
  const fieldRefValue = fieldRef.value as any
  fieldRefValue?.forEach((field: any) => {
    if (field.fieldKey === fieldKey) {
      field.resetField()
    }
  })
}

const vScrollToErrorField = scrollToErrorField

defineExpose({
  validateForm,
  resetField,
  resetForm
})
</script>

<template>
  <div ref="observer" class="asf-form">
    <AsfAlert
      v-if="error?.__general__"
      ref="generalErrorRef"
      class="asf-form__errors"
      :appearance="errorAppearance"
      alignment="center"
    >
      {{ typeof error?.__general__ === 'string' && $t(error?.__general__) }}
    </AsfAlert>
    <AsfAlert v-if="$slots['form-errors']" class="asf-form__errors" :appearance="errorAppearance" alignment="center">
      <slot name="form-errors" />
    </AsfAlert>
    <form
      :id="formId"
      ref="formRef"
      v-scroll-to-error-field
      :name="name"
      novalidate
      @submit.prevent="handleSubmit()"
      @reset.prevent="() => resetForm()"
    >
      <div
        v-for="(field, key) in innerFields"
        :key="`${formPrefix}-field-${key}`"
        :class="`asf-${formPrefix}-field-${key}`.toLowerCase()"
      >
        <slot :name="`${key}-slot-before`" />

        <component
          :is="getComponent(field)"
          v-bind="field"
          :options="field.options"
          :form="formId"
          :name="key"
          :key="key"
          :value="values[key]"
          ref="fieldRef"
        >
          <template v-if="$slots[`${key}-slot-label`]" #content-label>
            <slot :name="`${key}-slot-label`" />
          </template>
        </component>
        <slot :name="`${key}-slot-after`" />
      </div>
      <div v-if="$slots['form-actions']" class="asf-form__actions">
        <slot name="form-actions" />
      </div>
      <div v-if="$slots['form-buttons']" class="asf-form__buttons">
        <slot name="form-buttons" />
      </div>
    </form>
  </div>
</template>
<style lang="postcss">
@import '@components/molecules/Form/Form.css';
</style>
